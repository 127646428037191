import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { blue, indigo } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { Route, Switch, HashRouter } from 'react-router-dom';
import Main from './components/Main';
import About from './components/About';
import Topbar from './components/Topbar';
import { THContext } from './context';
import './App.css';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[900],
    },
    primary: {
      main: indigo[700],
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Helvetica', 'arial', 'sans-serif'].join(','),
  },
});
function App() {
  const [topbarHeight, setTopbarHeight] = useState(0);

  function updateHeight() {
    const tb = document.getElementById('TopBar');
    let height = 0;
    if (tb !== null) {
      height = tb.clientHeight;
    } else {
      height = 0;
    }
    if (topbarHeight !== height) {
      setTopbarHeight(window.innerHeight - height);
    }
  }
  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Box height="100vh" display="flex" flexDirection="column" overflow="hidden">
            <THContext.Provider value={topbarHeight}>
              <Box id="TopBar">
                <Topbar />
              </Box>
              <Box flex={1} overflow="hidden">
                <Switch>
                  <Route exact path="/" component={Main} />
                  <Route exact path="/About" component={About} />
                </Switch>
              </Box>
            </THContext.Provider>
          </Box>
        </HashRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
