import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collpase from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import tianhe from '../img/tianhe.jpg';
import iss from '../img/iss.jpg';

const styles = (_theme: any) => ({
  root: {
    width: '18em',
    marginTop: -5,
    marginLeft: 5,
  },
  satImg: {
    height: '100px',
  },

  dataColor: {
    color: '#aaa',
  },
  cardContent: {
    paddingTop: 10,
    paddingBottom: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardAction: {
    paddingTop: 2,
  },
});

function InfoBox(props: any) {
  const { classes, shadowInfo, t } = props;
  const [expanded, setExpanded] = useState(false);
  const detailRef = React.createRef();

  function triggerExpand() {
    // if (!expanded) {
    //   setOffset(-detailRef.current.parentElement.offsetHeight);
    // } else {
    //   setOffset(0);
    // }
    setExpanded(!expanded);
  }
  function cardImage() {
    const object = shadowInfo.object;
    if (object.includes('Tianhe')) {
      return tianhe;
    }
    if (object.includes('ISS')) {
      return iss;
    }
    return '';
  }

  function closeHandler() {
    window.displaybox.setOptions({ visible: false });
    setExpanded(false);
  }
  return ReactDOM.createPortal(
    <div>
      <Card className={classes.root}>
        <CardMedia title={shadowInfo.object} image={cardImage()} className={classes.satImg} />
        <CardContent className={classes.cardContent}>
          <Grid container direction="column" style={{ paddingRight: 2 }}>
            <Grid container item justify="space-between">
              <Grid item>
                <Typography variant="body1">
                  {t('Date')}
                  :
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.dataColor}>
                  {shadowInfo.utc.toLocaleDateString()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justify="space-between">
              <Grid item>
                <Typography variant="body1">
                  {t('Time')}
                  :
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.dataColor}>
                  {`${shadowInfo.utc.toLocaleTimeString('it-US')}.${Math.round(
                    shadowInfo.utc.getMilliseconds() / 100,
                    0,
                  )}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justify="space-between">
              <Grid item>
                <Typography variant="body1">
                  {t('Altitude(approximate)')}
                  :
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1" className={classes.dataColor}>
                  {shadowInfo.altitude}
                  °
                </Typography>
              </Grid>
            </Grid>
            <Collpase in={expanded} timeout="auto">
              <Grid container item justify="space-between" ref={detailRef}>
                <Grid item>
                  <Typography variant="body1">
                    {t('Azimuth(approximate)')}
                    :
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" className={classes.dataColor}>
                    {shadowInfo.azimuth}
                    °
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('Latitude')}
                    :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.dataColor}>
                    {shadowInfo.latitude.toFixed(6)}
                    °
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('Longitude')}
                    :
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" className={classes.dataColor}>
                    {shadowInfo.longitude.toFixed(6)}
                    °
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('Distance')}
                    :
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" className={classes.dataColor}>
                    {shadowInfo.distance}
                    km
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('Elevation')}
                    :
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" className={classes.dataColor}>
                    {shadowInfo.elevation}
                    m
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('Date(UTC)')}
                    :
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" className={classes.dataColor}>
                    {shadowInfo.utc.toISOString().split('T')[0]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('Time(UTC)')}
                    :
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" className={classes.dataColor}>
                    {shadowInfo.utc.toISOString().split('T')[1].split('Z')[0]}
                  </Typography>
                </Grid>
              </Grid>
            </Collpase>
          </Grid>
        </CardContent>
        <CardActions disableSpacing className={classes.cardAction}>
          <Grid container justify="space-between">
            <Grid item>
              <Button size="small" color="primary" onClick={closeHandler}>
                {t('Close')}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={triggerExpand}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
    </div>,
    document.getElementById('reactDisplayBox'),
  );
}

export default withTranslation('infoBox')(withStyles(styles)(InfoBox));
