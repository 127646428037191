let loading = false;
const loadBingMaps = (callback) => {
  const existingScript = document.getElementById('bingMapsAPI');
  if (!existingScript) {
    loading = true;
    const script = document.createElement('script');
    const callBackFnName = 'BingCallback';
    const key = process.env.NODE_ENV === 'development'
      ? 'ArgkqxUocI9XzUEfF8sUsffd-P85VaNkK4cNxjB5G9R_iWrNUf8kEElvzcpU4dJv'
      : 'Agr67a2-PWmHjC3Sk-yANlj856k0yihso9aFerS3gQAKnS2iFxMPzSzzY4ICuFY0';
    script.src = `https://www.bing.com/api/maps/mapcontrol?callback=${callBackFnName}&key=${key}`;
    script.id = 'bingMapsAPI';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    window[callBackFnName] = () => {
      loading = false;
      if (callback) callback();
    };
  } else if (existingScript && callback && !loading) {
    callback();
  }
};
export default loadBingMaps;
