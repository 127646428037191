/// <reference path="../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />
import React, { useEffect, useContext } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
// import ReactDOMServer from 'react-dom/server';
import { withTranslation } from 'react-i18next';
import { Fab, withWidth, Slide } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import { red, green } from '@material-ui/core/colors';
import InfoBox from './InfoBox';
import loadBingMaps from './BingMapsLoader';
import { THContext } from '../context';
import axios from '../http';
import ErrorBar from './ErrorBar';

const styles = (theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    textAlign: 'left',
    position: 'fixed',
    zIndex: 1000,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dateDescText: {
    fontSize: '0.7em',
    color: 'gray',
    marginTop: '5px',
  },
  dateText: {
    fontSize: '0.7em',
    color: '#bbb',
  },
  fab: {
    position: 'fixed',
    top: 'auto',
    left: 20,
    bottom: 20,
    zIndex: 100,
  },
});

function Main(props: any) {
  // const initPos = [39.9, 116.4];
  // const [selectStep, setSelectedStep] = React.useState(0);
  const [selectOptions, setSelectOptions] = React.useState([]);
  const [layers, setLayers] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);
  const [selectedObj, setSelectedObj] = React.useState('');
  const [selectedBg, setSelectedBg] = React.useState('');
  const [selectedTS, setSelectedTS] = React.useState('');
  const [selectedCS, setSelectedCS] = React.useState('WGS84');
  const [dataDate, setDataDate] = React.useState('');
  const [epochDate, setEpochDate] = React.useState('');
  const [shadowData, setShadowData] = React.useState(null);
  const [errorBarOpen, setErrorBarOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const mapRef = React.createRef();
  const localData = React.useRef({});
  const onChangedHandlerRef = React.useRef(null);
  const bodyHeight = useContext(THContext);
  const { classes, t, width } = props;

  // Filter Card
  const [filterCardOpen, setFilterCardOpen] = React.useState(false);
  const [mobileSwapped, setMobileSwapped] = React.useState(false);
  const toggleFilterCardOpen = () => {
    if (mobileSwapped) {
      setMobileSwapped(false);
    }
    setFilterCardOpen(!filterCardOpen);
  };

  // Width breakpoints
  const [mobile, setMobile] = React.useState(false);
  useEffect(() => {
    if (['xs', 'sm'].includes(width)) {
      if (!mobile) {
        setFilterCardOpen(false);
        setMobile(true);
        setMobileSwapped(true);
      }
    } else {
      setFilterCardOpen(true);
      setMobile(false);
    }
  }, [width]);

  function parseTime(str) {
    // const newStr = str.replace(/T/g, ' ');
    // return new Date(newStr.replace(/-/g, '/'));
    return new Date(`${str}Z`);
  }
  const PI = 3.1415926535897932384626;
  const a = 6378245.0;
  const ee = 0.00669342162296594323;
  function transformLng(lng, lat) {
    let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
    ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0;
    ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0;
    ret
      += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0;
    return ret;
  }
  function transformLat(lng, lat) {
    let ret = -100.0
      + 2.0 * lng
      + 3.0 * lat
      + 0.2 * lat * lat
      + 0.1 * lng * lat
      + 0.2 * Math.sqrt(Math.abs(lng));
    ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0;
    ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0;
    ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0;
    return ret;
  }
  function wgs84togcj02(lng, lat) {
    let dlat = transformLat(lng - 105.0, lat - 35.0);
    let dlng = transformLng(lng - 105.0, lat - 35.0);

    const radlat = (lat / 180.0) * PI;
    let magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    const sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
    const mglat = lat + dlat;
    const mglng = lng + dlng;
    return [mglng, mglat];
  }

  function pushpinClicked(e) {
    /*
    const desc = `UTC:${
      e.target.metadata.utc
    }<br/>Latitude:${e.target.metadata.latitude.toString()}<br/>Longitude:${e.target.metadata.longitude.toString()}<br/>Elevation:${e.target.metadata.elevation.toString()}<br/>Altitude(approximate):${e.target.metadata.altitude.toString()}<br/>Azimuth(approximate):${e.target.metadata.azimuth.toString()}<br/>Distance:${e.target.metadata.distance.toString()}<br/>Sun lit:${e.target.metadata.sunlit.toString()}`;
    */
    // const desc = ReactDOMServer.renderToStaticMarkup(infoBoxEle);
    /*
    window.displaybox.setOptions({
      location: e.target.getLocation(),
      title: 'shadow info',
      description: desc,
      showCloseButton: true,
      maxHeight: 400,
      maxWidth: 300,
      visible: true,
    });
    */

    setShadowData({ ...e.target.metadata, object: selectedObj });
    window.displaybox.setOptions({
      location: e.target.getLocation(),
      showCloseButton: true,
      visible: true,
    });
  }
  function drawData(data) {
    window.map.entities.clear();
    const displayPins = [];
    const displayLines = [];
    const rect = window.map.getBounds();
    const east = rect.getEast();
    const west = rect.getWest();
    const north = rect.getNorth();
    const south = rect.getSouth();
    const pan = Math.min(Math.abs(east - west), Math.abs(north - south));

    const visibleSatPoints = [];
    data.forEach((point) => {
      if (west < east) {
        if (
          point.latitude > south - 1
          && point.latitude < north + 1
          && point.longitude > west - 1
          && point.longitude < east + 1
        ) {
          visibleSatPoints.push(point);
        }
      } else if (
        point.latitude > south - 1
        && point.latitude < north + 1
        && (point.longitude > west - 1 || point.longitude < east + 1)
      ) {
        visibleSatPoints.push(point);
      }
    });

    let visibleSatPoints2 = [];

    visibleSatPoints.forEach((point) => {
      const time1 = point.utc;
      const second = time1.getSeconds();
      const milisecond = time1.getMilliseconds();
      const f1 = second % 10 === 0 && milisecond === 0 && pan > (10 * 60 * 8) / 110;
      const f2 = second % 10 === 0
        && milisecond === 0
        && pan <= (10 * 60 * 8) / 110
        && pan > (1 * 60 * 8) / 110;
      const f3 = milisecond === 0 && pan <= (60 * 8) / 110 && pan > (10 * 8) / 110;
      const f4 = pan <= (10 * 8) / 110 && pan > (1 * 8) / 110;
      const f5 = pan < (1 * 8) / 110;
      if (f1 || f2 || f3 || f4 || f5) {
        // visibleSatPoints2.push(JSON.parse(JSON.stringify(point)));
        visibleSatPoints2.push(point);
      }
    });

    if (selectedCS === 'GCJ-02') {
      visibleSatPoints2 = visibleSatPoints2.map((point) => {
        const newPoint = { ...point };
        const ab = wgs84togcj02(point.longitude, point.latitude);
        [newPoint.longitude, newPoint.latitude] = ab;
        return newPoint;
      });
    }
    visibleSatPoints2.forEach((point) => {
      const time1 = point.utc;
      const minute = time1.getMinutes();
      const second = time1.getSeconds();
      const milisecond = time1.getMilliseconds();
      const f1 = minute % 10 === 0 && second === 0 && milisecond === 0 && pan > (10 * 60 * 8) / 110;
      const f2 = second === 0 && milisecond === 0 && pan <= (10 * 60 * 8) / 110 && pan > (1 * 60 * 8) / 110;
      const f3 = second % 10 === 0 && milisecond === 0 && pan <= (60 * 8) / 110 && pan > (10 * 8) / 110;
      const f4 = milisecond === 0 && pan <= (10 * 8) / 110 && pan > (1 * 8) / 110;
      const f5 = pan < (1 * 8) / 110;
      if (f1 || f2 || f3 || f4 || f5) {
        const loc = new window.Microsoft.Maps.Location(point.latitude, point.longitude);
        const pointTimeMS = Math.round(point.utc.getMilliseconds() / 100, 0);
        let pointTimeMSStr = '';
        if (pointTimeMS !== 0) {
          pointTimeMSStr = `.${pointTimeMS}`;
        }
        const pointTime = point.utc.toLocaleTimeString('it-US');
        const pin = new window.Microsoft.Maps.Pushpin(loc, {
          // title: point.utc.split('T')[1],
          // subTitle: point.utc.split('T')[0],
          subTitle: point.utc.toLocaleDateString(),
          title: `${pointTime}${pointTimeMSStr}`,
        });
        pin.metadata = point;

        displayPins.push(pin);
        window.Microsoft.Maps.Events.addHandler(pin, 'click', pushpinClicked);
      }
    });
    window.map.entities.push(displayPins);

    if (visibleSatPoints2.length === 0) {
      return;
    }

    // Resort data
    visibleSatPoints2.sort((left, right) => left.utc.getTime() - right.utc.getTime());
    let time = visibleSatPoints2[0].utc.getTime();
    let coords = [];
    visibleSatPoints2.forEach((point) => {
      const time1 = point.utc.getTime();
      if (time1 - time < 60000) {
        coords.push(new window.Microsoft.Maps.Location(point.latitude, point.longitude));
        time = time1;
      } else {
        displayLines.push(
          new window.Microsoft.Maps.Polyline(coords, {
            strokeColor: 'red',
            strokeThickness: 2,
            strokeDashArray: [4, 0],
          }),
        );
        // map.entities.push(displayline);
        coords = [];
        coords.push(new window.Microsoft.Maps.Location(point.latitude, point.longitude));
        time = time1;
      }
    });
    displayLines.push(
      new window.Microsoft.Maps.Polyline(coords, {
        strokeColor: 'red',
        strokeThickness: 2,
        strokeDashArray: [4, 0],
      }),
    );
    window.map.entities.push(displayLines);
  }
  function parseTxt(text) {
    const lines = text.split('\n');
    const json = [];
    for (let i = 0; i < lines.length - 1; i += 1) {
      const elements = lines[i].split(',');
      const ejson = {};
      ejson.utc = parseTime(elements[0]);
      ejson.latitude = parseFloat(elements[1]);
      ejson.longitude = parseFloat(elements[2]);
      ejson.elevation = parseFloat(elements[3]);
      ejson.altitude = parseFloat(elements[4]);
      ejson.azimuth = parseFloat(elements[5]);
      ejson.distance = parseFloat(elements[6]);
      ejson.sunlit = elements[6] === 'true';
      json.push(ejson);
    }
    return json;
  }
  function drawTile(dataline, tileKey, url: string) {
    setDataDate(dataline.date);
    setEpochDate(dataline.epoch);
    if (tileKey in dataline.tiles) {
      if (dataline.tiles[tileKey] === true) {
        drawData(dataline.data);
        return;
      }
      // Another request is waiting.
      dataline.tiles[tileKey]();
    }
    setDataLoading(true);
    axios
      .get(url, {
        cancelToken: new axios.CancelToken((c) => {
          dataline.tiles[tileKey] = c;
        }),
      })
      .then((resp) => {
        setDataLoading(false);
        dataline.tiles[tileKey] = true;
        dataline.data = dataline.data.concat(parseTxt(resp.data));
        drawData(dataline.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Pass
        } else {
          setDataLoading(false);
          if (error.response === undefined) {
            setErrorMsg(String(error));
            setErrorBarOpen(true);
            throw error;
          } else if (error.response.status === 404) {
            setErrorMsg("Error: Can't reach resource files");
          } else if (error.response.status === 408) {
            setErrorMsg('Error: Request timeout.');
          } else {
            setErrorMsg(String(error));
          }
          setErrorBarOpen(true);
        }
      });
  }
  function updateGraph() {
    if (selectedObj !== '' && selectedBg !== '' && selectedCS !== '' && selectedTS !== '') {
      // Cancel previous draw
      // Construct key for local storage.
      const dataKey = selectedObj + selectedBg + selectedTS;
      const currentLocalData = localData.current;

      // Get or create based on key
      if (!(dataKey in currentLocalData)) {
        const data = selectOptions.filter(
          (value) => value.obj === selectedObj && value.bg === selectedBg && value.timespan === selectedTS,
        );
        if (data.length !== 1) {
          console.error("Can't get the unique data line");
          return;
        }
        currentLocalData[dataKey] = data[0];
        currentLocalData[dataKey].data = [];
        currentLocalData[dataKey].tiles = {};
      }

      const dataline = currentLocalData[dataKey];

      const rect = window.map.getBounds();
      const east = rect.getEast();
      const west = rect.getWest();
      const north = rect.getNorth();
      const south = rect.getSouth();
      const pan = Math.min(Math.abs(east - west), Math.abs(north - south));

      for (let i = 0; i < layers.length; i += 1) {
        if (i === 0 || (pan * 110) / 8 < layers[i].resolution * 400) {
          const xIndexL = Math.floor((west + 180) / layers[i].tilesize);
          const xIndexR = Math.floor((east + 180) / layers[i].tilesize);
          const yIndexT = Math.floor((north + 90) / layers[i].tilesize);
          const yIndexB = Math.floor((south + 90) / layers[i].tilesize);

          for (let j = 0; j < 360 / layers[i].tilesize; j += 1) {
            for (let k = 0; k < 180 / layers[i].tilesize; k += 1) {
              const url = `slice/report_${selectedBg.split('|')[0]}_${
                selectedObj.split('|')[0]
              }_${selectedTS}_l${String(layers[i].level)}x${String(j)}y${String(k)}.txt`;
              const tileKey = `${layers[i].level}|${j}|${k}`;

              if (west < east) {
                if (j >= xIndexL && j <= xIndexR && k >= yIndexB && k <= yIndexT) {
                  drawTile(dataline, tileKey, url);
                }
              }
              if (east < west) {
                if ((j >= xIndexL || j <= xIndexR) && k >= yIndexB && k <= yIndexT) {
                  drawTile(dataline, tileKey, url);
                }
              }
            }
          }
        }
      }
    }
  }
  function getObjSet() {
    const objSet = new Set(selectOptions.map((value) => value.obj));
    return Array.from(objSet);
  }

  function getBgSet() {
    const bgSet = new Set(
      selectOptions.filter((value) => value.obj === selectedObj).map((value) => value.bg),
    );
    return Array.from(bgSet);
  }
  function getTSSet() {
    const tsSet = new Set(
      selectOptions
        .filter((value) => value.obj === selectedObj && value.bg === selectedBg)
        .map((value) => value.timespan),
    );
    return Array.from(tsSet);
  }

  useEffect(() => {
    if (window.Microsoft === undefined) {
      return;
    }
    window.displaybox.setOptions({ visible: false });
    if (onChangedHandlerRef.current) {
      window.Microsoft.Maps.Events.removeHandler(onChangedHandlerRef.current);
    }

    onChangedHandlerRef.current = window.Microsoft.Maps.Events.addHandler(
      window.map,
      'viewchangeend',
      updateGraph,
    );

    updateGraph();
  }, [selectedObj, selectedBg, selectedCS, selectedTS, selectOptions]);

  function handleSelect(target) {
    if (target === 'obj') {
      return (event) => setSelectedObj(event.target.value);
    }
    if (target === 'bg') {
      return (event) => setSelectedBg(event.target.value);
    }
    if (target === 'ts') {
      return (event) => setSelectedTS(event.target.value);
    }
    if (target === 'cs') {
      return (event) => setSelectedCS(event.target.value);
    }
    return () => {};
  }

  const [currentTime, setCurrentTime] = React.useState(new Date());
  function tick() {
    setCurrentTime(new Date());
  }

  function dataDateStr() {
    const timeDiff = currentTime - new Date(`${dataDate}Z`);
    const hour = parseInt(timeDiff / 3600000, 10);
    const minute = parseInt((timeDiff - hour * 3600000) / 60000, 10);
    return `${hour} ${t('Hour')} ${minute} ${t('Minutes')} ${t('ago')}`;
  }
  function epochDateStr() {
    const timeDiff = currentTime - new Date(epochDate);
    const hour = parseInt(timeDiff / 3600000, 10);
    const minute = parseInt((timeDiff - hour * 3600000) / 60000, 10);
    return `${hour} ${t('Hour')} ${minute} ${t('Minutes')} ${t('ago')}`;
  }

  useEffect(() => {
    // Mount hook.
    setLoading(true);
    setInterval(() => {
      tick();
    }, 10000);

    loadBingMaps(() => {
      const { Maps } = window.Microsoft;

      // const loc = new Maps.Location(...initPos);
      const option = {
        // center: loc,
        mapTypeId: Maps.MapTypeId.aerial,
        zoom: 10,
      };
      const mainMap = document.getElementById('mainMap');
      window.map = new Maps.Map(mainMap, option);
      window.displaybox = new Maps.Infobox(window.map.getCenter(), { visible: false });
      window.displaybox.setMap(window.map);
      window.displaybox.setOptions({ htmlContent: "<div id='reactDisplayBox'/>" });
      onChangedHandlerRef.current = window.Microsoft.Maps.Events.addHandler(
        window.map,
        'viewchangeend',
        () => {},
      );
      const mapType = window.map.getMapTypeId();
      if (mapType !== 'a') {
        setSelectedCS('GCJ-02');
      }
      const contentsResource = axios.get('slice/contents.json');
      const layersResource = axios.get('slice/layer.json');
      axios
        .all([contentsResource, layersResource])
        .then(
          axios.spread((contentsResp, layersResp) => {
            setLoading(false);
            setSelectOptions(contentsResp.data);
            const firstData = contentsResp.data[0];
            setLayers(layersResp.data);
            setSelectedObj(firstData.obj);
            setSelectedBg(firstData.bg);
            setSelectedTS(firstData.timespan);
          }),
        )
        .catch((err) => {
          setLoading(false);
          if (err.response === undefined) {
            setErrorMsg('Error: Request timeout.');
          } else if (err.response.status === 404) {
            setErrorMsg("Error: Can't reach resource files");
          } else if (err.response.status === 408) {
            setErrorMsg('Error: Request timeout.');
          } else {
            setErrorMsg(String(err));
          }
          setErrorBarOpen(true);
        });
    });
  }, []);

  function onCloseErrorBar(_event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setErrorBarOpen(false);
  }
  return (
    <div>
      <Grid container justify="center">
        <div ref={mapRef} id="mainMap" style={{ height: `${bodyHeight}px` }} />
      </Grid>
      <Grid>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Slide direction="right" in={filterCardOpen} mountOnEnter unmountOnExit>
          {true && (
            <Card
              className={classes.paper}
              style={{
                top:
                  !mobile || mobileSwapped ? `${window.innerHeight - bodyHeight + 10}px` : 'auto',
                bottom: mobile && !mobileSwapped ? '80px' : 'auto',
                left: '10px',
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="obj-label">
                      {t('Object')}
                      :
                    </InputLabel>
                    <Select
                      labelId="obj-label"
                      id="obj"
                      style={{ width: '150px' }}
                      value={selectedObj}
                      onChange={handleSelect('obj')}
                    >
                      {getObjSet().map((value) => (
                        <MenuItem value={value}>{t(value)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {selectedObj !== '' && (
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="bg-label">
                        {t('BG')}
                        :
                      </InputLabel>
                      <Select
                        labelId="bg-label"
                        id="bg"
                        style={{ width: '150px' }}
                        value={selectedBg}
                        onChange={handleSelect('bg')}
                      >
                        {getBgSet().map((value) => (
                          <MenuItem value={value}>{t(value)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {selectedBg !== '' && (
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="ts-label">
                        {t('Time Span')}
                        :
                      </InputLabel>
                      <Select
                        labelId="ts-label"
                        id="ts"
                        style={{ width: '150px' }}
                        value={selectedTS}
                        onChange={handleSelect('ts')}
                      >
                        {getTSSet().map((value) => (
                          <MenuItem value={value}>{value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="cs-label">
                      {t('Coordinate')}
                      :
                    </InputLabel>
                    <Select
                      labelId="cs-label"
                      id="cs"
                      style={{ width: '150px' }}
                      value={selectedCS}
                      onChange={handleSelect('cs')}
                    >
                      {['WGS84', 'GCJ-02'].map((value) => (
                        <MenuItem value={value}>{value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {selectedCS === 'WGS84' && (
                  <Grid item style={{ width: '150px' }}>
                    <Typography className={classes.dateDescText} variant="body2">
                      {t('WGS84Desc')}
                    </Typography>
                  </Grid>
                )}
                {selectedCS === 'GCJ-02' && (
                <Grid item>
                  <Typography
                    className={classes.dateDescText}
                    variant="body2"
                    style={{ width: '150px' }}
                  >
                    {t('GCJ-02Desc')}
                  </Typography>
                </Grid>
                )}
                {dataLoading && (
                  <Grid container justify="center" style={{ marginTop: '10px' }}>
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {!dataLoading && dataDate && epochDate && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: '10px' }}
                >
                  <Grid item>
                    <Typography className={classes.dateDescText} variant="body2">
                      {t('Data generated at')}
                      :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={`${dataDate}Z`} placement="top">
                      <Typography className={classes.dateText} variant="body2">
                        {dataDateStr()}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.dateDescText} variant="body2">
                      {t('Satellite TLE epoch')}
                      :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={epochDate} placement="top">
                      <Typography className={classes.dateText} variant="body2">
                        {epochDateStr()}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Card>
          )}
        </Slide>
      </Grid>
      {shadowData && <InfoBox shadowInfo={shadowData} />}
      <ErrorBar open={errorBarOpen} msg={errorMsg} onClose={onCloseErrorBar} />
      <Slide direction="right" in={mobile} mountOnEnter unmountOnExit>
        <Fab
          className={classes.fab}
          onClick={toggleFilterCardOpen}
          style={{ backgroundColor: filterCardOpen ? green[300] : red[300] }}
        >
          {!filterCardOpen ? <SearchIcon /> : <CheckIcon />}
        </Fab>
      </Slide>
    </div>
  );
}

export default withWidth()(withTranslation()(withStyles(styles)(Main)));
