import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from 'translations/translation-en.json';
import infoBoxEN from 'translations/infoBox-en.json';
import translationZH from 'translations/translation-zh.json';
import infoBoxZH from 'translations/infoBox-zh.json';

const resources = {
  en: {
    translation: translationEN,
    infoBox: infoBoxEN,
  },
  zh: {
    translation: translationZH,
    infoBox: infoBoxZH,
  },
};
const detectorOptions = {
  order: ['querystring', 'navigator', 'htmlTag', 'mapLang'],
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    detection: detectorOptions,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
