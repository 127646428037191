import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import alipay from '../img/alipay.jpg';

const styles = (theme: any) => ({
  root: {
    backgroundColor: theme.palette.grey['100'],
    overflow: 'auto',
    height: '100%',
  },
  card: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  img: {
    height: '13em',
    width: '8.6em',
  },
});

function About(props: any) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={1}>
          <Grid item>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid>
                    <Typography variant="h4" gutterBottom paragraph>
                      About Us
                    </Typography>
                    <Typography variant="body1" component="p" paragraph>
                      SatShadow网站由quan787和moland96共同创办，提供中国空间站和国际空间站凌日月、掩凌主要行星和恒星的地面轨迹预报数据我们原则上不对数据精度做任何承诺。但在平原地区，理论上可以达到大约50m的精度。误差主要来自以下方面：卫星的TLE数据只保证1km以内的误差，不同的算法计算出的卫星位置也并不一定互相吻合；本网站提供的预报数据基于90m分辨率的地形高程模型，其精度在16m之内；为了计算性能，程序未对卫星的光行差进行精确补偿，这会在卫星行进方向上带来约10m的误差。
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="body1" component="p" paragraph>
                      请注意中国空间站配备的霍尔效应推进器有可能长时间工作，导致其轨道持续改变，即使最新生成的数据也会有数百米的误差。
                      计算程序使用了skyfield（
                      <a href="https://github.com/skyfielders/python-skyfield">github</a>
                      ）和python-srtm（
                      <a href="https://github.com/adamcharnock/python-srtm">github</a>
                      ）。 如果您觉得本网站对您的观测有帮助，请考虑按照以下方式对我们进行捐助。
                    </Typography>
                    <Typography variant="body1" component="p" paragraph>
                      SatShadow.top is created by quan787 and moland96, providing predictions of CSS
                      and ISS transiting/occulting major celestial bodies. We don&apos;t collect any
                      of your personal data, especially location. Any calculations involving your
                      location is performed locally in your browser. This website doesn’t use
                      SatShadow.top is created by quan787 and moland96, providing predictions of CSS
                      and ISS transiting/occulting major celestial bodies. Generally, we don&apos;t
                      make any guarantee about accuracies. But on flat lands, a spatial accuracy of
                      tens of meters can be expected. In general, TLE data only guarantee an
                      accuracy of about 1km. Data are generated based on SRTM dataset which has 90m
                      resolution and 16m vertical accuracy. For the sake of performance, it
                      didn&apos;t compensate the aberration of satellites, which introduces a
                      negligible error of ~10m.
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="body1" component="p" paragraph>
                      Note that CSS core module Tianhe is equipped with 4 Hall effect thrusters that
                      may constantly boost its orbit, changing its orbit, which will result in
                      hundreds of meters of deviation even with the latest TLE data. The software to
                      generate the data uses skyfield (
                      <a href="https://github.com/skyfielders/python-skyfield">github</a>
                      ) and
                      python-srtm (
                      <a href="https://github.com/adamcharnock/python-srtm">github</a>
                      ). If you find this website helpful, please consider donation via methods
                      listed below.
                    </Typography>
                  </Grid>
                  <Grid style={{ marginTop: 20 }}>
                    <Typography variant="body1" component="p" paragraph>
                      Donation Links:
                    </Typography>
                  </Grid>
                  <Grid container item alignItems="flex-start" justify="center" spacing={9}>
                    <Grid item>
                      <Typography variant="body1" component="p" paragraph>
                        支付宝:
                      </Typography>
                      <Card>
                        <CardMedia title="Alipay" image={alipay} className={classes.img} />
                      </Card>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" component="p" paragraph>
                        Paypal:
                      </Typography>
                      <form
                        action="https://www.paypal.com/cgi-bin/webscr"
                        method="post"
                        target="_top"
                      >
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="Q5X64A8N87QMY" />
                        <input
                          type="image"
                          src="https://www.paypalobjects.com/en_US/C2/i/btn/btn_buynowCC_LG.gif"
                          border="0"
                          name="submit"
                          alt="PayPal——最安全便捷的在线支付方式！"
                        />
                        <img
                          alt=""
                          border="0"
                          src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                          width="1"
                          height="1"
                        />
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(About);
