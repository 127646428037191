import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import TranslateIcon from '@material-ui/icons/Translate';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Link as MaterialLink } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import AppMenu from './Menu';

import logo from '../img/logo.png';

const styles = (theme: any) => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    backgroundColor: 'white',
  },
  inline: {
    display: 'inline',
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  },
  rightFlex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  productLogo: {
    display: 'inline-block',
    borderLeft: `1px solid ${theme.palette.grey.A100}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: '20px',
    },
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: '0.8em',
    },
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  iconButton: {
    float: 'right',
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto',
  },
  language: {},
  languageText: {
    marginLeft: 8,
    marginRight: 4,
  },
});

function Topbar(props: any) {
  const [menuDrawer, setMenuDrawer] = useState(false);
  const { classes, location, t, i18n, width } = props;

  const [languageMenu, setLanguageMenu] = React.useState(null);
  const handleLanguageIconClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };
  const handleLanguageMenuClose = (event) => {
    if (event.currentTarget.nodeName === 'A') {
      i18n.changeLanguage(event.currentTarget.lang);
    }
    setLanguageMenu(null);
  };

  const mobileMenuOpen = (_event: any) => {
    setMenuDrawer(true);
  };

  const mobileMenuClose = () => {
    setMenuDrawer(false);
  };

  const currentLanguage = () => {
    switch (i18n.language) {
      case 'zh':
        return '中文';
      case 'en':
        return 'English';
      default:
        return 'English';
    }
  };
  const current = () => {
    if (location.pathname === '/Home') {
      return 0;
    }
    if (location.pathname === '/About') {
      return 1;
    }
    return 0;
  };

  const [mobile, setMobile] = React.useState(false);
  useEffect(() => {
    if (width === 'xs') {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  });

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
        <Grid container spacing={4} alignItems="center" justify="space-between">
          <Grid item className={classes.flex} xs={8} md="auto">
            <div className={classes.inline}>
              <Typography variant="h6" color="inherit" noWrap>
                <Link to="/" className={classes.link}>
                  <Grid container alignItems="center">
                    <img className={classes.tagline} width={30} src={logo} alt="" />
                    <span className={classes.tagline}>SatShadow</span>
                  </Grid>
                </Link>
              </Typography>
            </div>
            <>
              <div className={classes.productLogo}>
                <Typography>{!mobile && t('Satellite Transit/Occultation Calculator')}</Typography>
              </div>
              <div className={classes.tabContainer}>
                <SwipeableDrawer
                  anchor="right"
                  open={menuDrawer}
                  onClose={mobileMenuClose}
                  onOpen={mobileMenuOpen}
                >
                  <AppBar title="Menu" />
                  <List>
                    {AppMenu.map((item, _index) => (
                      <ListItem
                        // @ts-ignore
                        component={item.external ? MaterialLink : Link}
                        href={item.external ? item.pathname : null}
                        to={
                          item.external
                            ? null
                            : {
                              pathname: item.pathname,
                              search: location.search,
                            }
                        }
                        button
                        key={item.label}
                      >
                        <ListItemText primary={t(item.label)} />
                      </ListItem>
                    ))}
                  </List>
                </SwipeableDrawer>
                <Tabs value={current()} indicatorColor="primary" textColor="primary">
                  {AppMenu.map((item, index) => (
                    <Tab
                      key={index}
                      // @ts-ignore
                      component={item.external ? MaterialLink : Link}
                      href={item.external ? item.pathname : null}
                      to={
                        item.external
                          ? null
                          : {
                            pathname: item.pathname,
                            search: location.search,
                          }
                      }
                      classes={{ root: classes.tabItem }}
                      label={t(item.label)}
                    />
                  ))}
                </Tabs>
              </div>
            </>
          </Grid>
          <Grid item className={classes.rightFlex} xs={4} md="auto">
            <div>
              <Button
                color="inherit"
                aria-label="language"
                className={classes.language}
                onClick={handleLanguageIconClick}
              >
                <TranslateIcon />
                {!mobile && <div className={classes.languageText}>{currentLanguage()}</div>}
                <ExpandMoreIcon fontSize="small" />
              </Button>
              <Menu
                id="language-menu"
                anchorEl={languageMenu}
                open={Boolean(languageMenu)}
                onClose={handleLanguageMenuClose}
              >
                <MenuItem
                  component="a"
                  data-no-link="true"
                  key="English"
                  lang="en"
                  onClick={handleLanguageMenuClose}
                >
                  English
                </MenuItem>
                <MenuItem
                  component="a"
                  data-no-link="true"
                  key="简体中文"
                  lang="zh"
                  onClick={handleLanguageMenuClose}
                >
                  简体中文
                </MenuItem>
              </Menu>
            </div>
            <div className={classes.iconContainer}>
              <IconButton
                onClick={mobileMenuOpen}
                className={classes.iconButton}
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

// @ts-ignore
export default withTranslation()(withRouter(withStyles(styles)(withWidth()(Topbar))));
