const Menu = [
  {
    label: 'Home',
    pathname: '/',
  },
  {
    label: 'About',
    pathname: '/About',
  },
  /*
  {
    label: 'Google',
    pathname: 'https://www.google.com',
    external: true,
  }, */
];

export default Menu;
